import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { endpoint } from "../utils/endpoint";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("tokens"));
    const fetchUser = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch(`${endpoint}/user/api/users/me`, requestOptions);
        if(!response.ok) {
            setToken(null);
        }
        localStorage.setItem("tokens", token);
    };

    useEffect(() => {
        fetchUser();
    }, [token]);

    return(
        <UserContext.Provider value={[token, setToken]} >
            {props.children}
        </UserContext.Provider>
    );
};
