import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
  Typography,
  Table,
  Input,
  Tag,
  Space,
} from "antd";
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { endpoint } from '../../utils/endpoint';
import axios from 'axios';
import { withStyles, MenuItem } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const { Title } = Typography;

const columns = [
  {
    title: 'NO',
    dataIndex: 'No',
    width: '1%',
    key: "No"
  },
  {
    title: 'Username',
    dataIndex: 'username',
    sorter: true,
    width: '20%',
  },
  {
    title: 'Level',
    dataIndex: 'level',
    width: '30%',
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    width: '20%',
    key: "action"
  },
];

const pencil = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
      className="fill-gray-7"
    ></path>
    <path
      d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
      className="fill-gray-7"
    ></path>
  </svg>,
];

const deletebtn = [
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
      fill="#111827"
      className="fill-danger"
    ></path>
  </svg>,
];

const User = () => {
  const [dataUser, setDataUser] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [ids, setIds] = useState(0);
  const [idP, setIdP] = useState(0);
  const [dataProvinsi, setProvinsi] = useState([]);
  const [dataKota, setDataKota] = useState([]);
  const [kabkota, setKabkota] = useState('');
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [level, setLevel] = useState('');
  const [regional, setRegional] = useState('');
  const getToken = localStorage.getItem("tokens");
  const [token, setToken] = useState(getToken || null);

  const getProvinsi = async () => {
    await axios.get(`${endpoint}/lokasi/`)
    .then((res) => {
      setProvinsi(res.data.data);
      console.log(res.data.data);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getProvinsi();
  }, []);

  const getKota = async () => {
    await axios.get(`${endpoint}/lokasi/${ids}`)
    .then((res) => {
      setDataKota(res.data.data);
      console.log(res.data.data);
      let province = dataProvinsi.filter(data => data.id === ids.toString());
      let getProv = province.map(data => data.name);
      setRegional(getProv.toString());
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getKota();
  }, [ids]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEdit= () => {
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
  };


  const fetchData = async (page) => {
    setLoading(true);
    await axios.get(`${endpoint}/user/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }).then((res) => {
        setDataUser(res.data);
        setTotalPages(res.data.totalPages);
        setLoading(false);
        console.log(res.data);
      });
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  console.log(regional)

  const deleteUsers = async (id) => {
    await axios
      .delete(`${endpoint}/user/delete_user/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then(() => {
        setDataUser((currentTodos) =>
          currentTodos.filter((todo) => todo.id !== id)
        );
      })
      .catch((error) => {
        console.error("There was an error deleting the todo: ", error);
      });
  }

  const data = dataUser.map((d, i) => (
    {
      No: (
        <>
            <div className="avatar-info">
              <Title level={5}>{i+1}.</Title>
            </div>
        </>
      ),
      username: (
        <>
            <div className="avatar-info">
              <Title level={5}>{d.username}</Title>
            </div>
        </>
      ),
      level: (
        <>
            <div className="avatar-info">
              <Title level={5}>{d.level}</Title>
            </div>
        </>
      ),
      action: (
        <>
            <div className="avatar-info">
            <Space direction="vertical">
            <Space wrap>
            <Button type="link" className="darkbtn" onClick={handleClickEdit}>
              {pencil} EDIT
            </Button>
            <Dialog open={editOpen} onClose={handleCloseEdit}>
              <DialogTitle>Form Edit User</DialogTitle>
              <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Username"
                  id="username"
                  onChange={(e) => {setUsername(e.target.value)}}
                />
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Password"
                  id="password"
                  onChange={(e) => {setPassword(e.target.value)}}
                />
                <TextField
                  style={{ marginTop: 10 }}
                  label="Regional"
                  fullWidth
                  select
                  variant="outlined"
                  id="country"
                  margin="dense"
                  onChange={(e) => {setRegional(e.target.value)}}
                >
                <MenuItem key="PUSAT" value="All">
                    PUSAT (Super Admin)
                </MenuItem>
                {dataProvinsi.map((data) => (
                  <MenuItem key={data.name} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
                </TextField>
                <TextField
                  style={{ marginTop: 10 }}
                  label="Level / Regional"
                  fullWidth
                  select
                  variant="outlined"
                  id="country"
                  margin="dense"
                  onChange={(e) => {setLevel(e.target.value)}}
                >
                <MenuItem key="Admin Pusat" value="Admin Pusat">
                    Admin Pusat (Super Admin)
                </MenuItem>
                <MenuItem key="Admin Provinsi" value="Admin Provinsi">
                    Admin Provinsi
                </MenuItem>
                <MenuItem key="Admin Kabupaten" value="Admin Kabupaten">
                    Admin Kabupaten
                </MenuItem>
                </TextField>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEdit}>Cancel</Button>
                <Button type="primary">Submit</Button>
              </DialogActions>
            </Dialog>
            <Button type="link" danger onClick={() => deleteUsers(d.id)}>
              {deletebtn}DELETE
            </Button>
            </Space>
          </Space>
            </div>
        </>
      )
    }
  ))

  const tambahUser = async () => {
      await axios.post(`${endpoint}/user/create_user`, {
        "username": username, "password": password, "regional": regional, "level": level
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then((res) => {
        message.success("Data berhasil di simpan");
        handleClose();
        console.log(res);
      }).catch(err => {
          message.error("Ada Kesalahan atau Username Telah Terdapat")
          console.log("Error")
      }) 
  }

    
  return (
    <div className="tabled">
         <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Tabel User"
              extra={
                  <>
                    <Radio.Group defaultValue="a">
                    <Button type="primary" onClick={handleClickOpen}>
                        Tambah User +
                      </Button>
                      <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>Form Tambah User</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <TextField
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          label="Username"
                          id="username"
                          onChange={(e) => {setUsername(e.target.value)}}
                        />
                        <TextField
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          label="Password"
                          id="password"
                          onChange={(e) => {setPassword(e.target.value)}}
                        />
                        <TextField
                          style={{ marginTop: 10 }}
                          label="Regional"
                          fullWidth
                          select
                          variant="outlined"
                          id="country"
                          margin="dense"
                          onChange={(e) => {
                            setIds(parseInt(e.target.value))
                            setIdP(parseInt(e.target.value))
                            setRegional(e.target.value)
                          }}
                        >
                        <MenuItem key="PUSAT" value="All">
                          PUSAT (Super Admin)
                        </MenuItem>
                        {dataProvinsi.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                        </TextField>
                        <TextField
                          style={{ marginTop: 10 }}
                          label="Kabupaten Kota"
                          fullWidth
                          select
                          variant="outlined"
                          id="kabKota"
                          margin="dense"
                          onChange={(e) => {setKabkota(e.target.value)}}
                        >
                          {dataKota.map((kota) => (
                            <MenuItem key={kota.id} value={kota.name}>
                              {kota.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ marginTop: 10 }}
                          label="Level"
                          fullWidth
                          select
                          variant="outlined"
                          id="country"
                          margin="dense"
                          onChange={(e) => {setLevel(e.target.value)}}
                        >
                        <MenuItem key="Admin Pusat" value="Admin Pusat">
                            Admin Pusat
                        </MenuItem>
                        <MenuItem key="Admin Provinsi" value="Admin Provinsi">
                            Admin Provinsi
                        </MenuItem>
                        <MenuItem key="Admin Kabupaten" value="Admin Kabupaten">
                            Admin Kabupaten
                        </MenuItem>
                        </TextField>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="primary" onClick={tambahUser}>Submit</Button>
                      </DialogActions>
                    </Dialog>
                    </Radio.Group>
                  </>
              }
            >
            <div className='table-responsive'>
                <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{
                    pageSize: 10,
                    total: totalPages,
                    onChange: (page) => {
                      fetchData(page);
                    },
                }}
                />
            </div>
            </Card>
            </Col>
         </Row>
    </div>
  );
};
export default User;