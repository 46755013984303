/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Typography, Skeleton } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { endpoint } from "../../utils/endpoint";


function LineChart() {
  const { Title, Paragraph } = Typography;
  const [driven, setDriven] = useState([]);
  const [loading, setLoading] = useState(false);

    const getData = async () => {
      setLoading(true);
      await axios.get(`${endpoint}/data_driven/label_statistik`)
      .then((res) => {
        setDriven(res.data);
        console.log(res.data);
        setLoading(false);
      }).catch(err => console.log(err));
    };

    useEffect(() => {
        getData();
    }, []);
    
    let ar1 = parseInt(driven[0]);
    let ar2 = parseInt(driven[1]);
    let ar3 = parseInt(driven[2]);

  return (
    <React.Fragment>
    <Skeleton loading={loading}>
      <div className="linechart">
        <div>
          <Title level={5}>Grafik Survey Perusahaan Seluruh Indonesia</Title>
          <br />
        </div>
      </div>

      <ReactApexChart
        options={lineChart.options}
        series={[ar1,ar2,ar3]}
        height={350}
        type="donut"
        width={"100%"}
        key={1}
      />
    </Skeleton>
    </React.Fragment>
  );
}

export default LineChart;
