/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import {Card,Col,Row,Typography,Tooltip,Progress,Upload,message,Button,Timeline,Radio} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";
import axios from "axios";
import ava1 from "../assets/images/logo-shopify.svg";
import team1 from "../assets/images/team-1.jpg";
import { endpoint } from "../utils/endpoint";

function Home() {
  const { Title, Text } = Typography;

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);

  const [reverse, setReverse] = useState(false);
  const [data, setData] = useState([]);
  const [dataProv, setDataProv] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(localStorage.getItem("regional"));
  const [level, setLevel] = useState(localStorage.getItem("level"));
  const getDriven = async () => {
      await axios.get(`${endpoint}/data_driven/statistik`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      }).catch(err => console.log(err));
  };

  useEffect(() => {
      getDriven();
  }, []);

  const getRank = async () => {
      await axios.get(`${endpoint}/company/get_ranking`)
      .then((res) => {
          setRanking(res.data);
      }).catch(err => console.log(err));
  }

  useEffect(() => {
      getRank();
  }, [])

  const getDataByProv = async (e) => {
    if (region !== "All" && e === undefined) {
      e = region;
      console.log(e);
    }
    await axios.get(`${endpoint}/data_driven/statistika/${region}`)
    .then((res) => {
      setDataProv(res.data)
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    getDataByProv();
  }, []);

  const getProvinsi = async () => {
    setLoading(true);
    await axios.get(`${endpoint}/lokasi/`)
    .then((res) => {
      setProvinsi(res.data.data);
      console.log(res.data.data);
      setLoading(false);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getProvinsi();
  }, []);

  const profile = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const heart = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const cart = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const count = [
    {
      today: "Total Perusahaan",
      title: data[0],
      persent: "",
      icon: profile,
      bnb: "bnb2",
      color: "#15406A"
    },
    {
      today: "Berlabel Hijau",
      title: data[1],
      persent: "",
      icon: profile,
      bnb: "bnb2",
      color: "#2EDB33"
    },
    {
      today: "Berlabel Kuning",
      title: data[2],
      persent: "",
      icon: profile,
      bnb: "redtext",
      color: "#EFE80B"
    },
    {
      today: "Berlabel Merah",
      title: data[3],
      persent: "",
      icon: profile,
      bnb: "bnb2",
      color: "#F20428"
    },
  ];

  const count2 = [
    {
      today: "Total Perusahaan",
      title: dataProv[0] + dataProv[1] + dataProv[2],
      persent: "",
      icon: profile,
      bnb: "bnb2",
      color: "#15406A"
    },
    {
      today: "Berlabel Hijau",
      title: dataProv[0],
      persent: "",
      icon: profile,
      bnb: "bnb2",
      color: "#2EDB33"
    },
    {
      today: "Berlabel Kuning",
      title: dataProv[1],
      persent: "",
      icon: profile,
      bnb: "redtext",
      color: "#EFE80B"
    },
    {
      today: "Berlabel Merah",
      title: dataProv[2],
      persent: "",
      icon: profile,
      bnb: "bnb2",
      color: "#F20428"
    },
  ];

  const list = [
    ranking.map((data) => ({
      Title: data.nama_perusahaan,
      bud: data.score,
      progress: <Progress percent={data.score} size="small" />,
      provinsi: data.provinsi,
      kota: data.kota
    }))
  ];

  const timelineList = [
    {
      title: "$2,400 - Redesign store",
      time: "09 JUN 7:20 PM",
      color: "green",
    },
    {
      title: "New order #3654323",
      time: "08 JUN 12:20 PM",
      color: "green",
    },
    {
      title: "Company server payments",
      time: "04 JUN 3:10 PM",
    },
    {
      title: "New card added for order #4826321",
      time: "02 JUN 2:45 PM",
    },
    {
      title: "Unlock folders for development",
      time: "18 MAY 1:30 PM",
    },
    {
      title: "New order #46282344",
      time: "14 MAY 3:30 PM",
      color: "gray",
    },
  ];

  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <div className="layout-content">
        {region === "All" ? (
            <Row className="rowgap-vbox" gutter={[24, 0]}>
            {count.map((c, index) => (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                className="mb-24"
              >
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span>{c.today}</span>
                        <Title level={3}>
                          {c.title} <small className={c.bnb}>{c.persent}</small>
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box" style={{background: `${c.color}`}}>{c.icon}</div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count2.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box" style={{background: `${c.color}`}}>{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        )}
        
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={14} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>DATA SELURUH PROVINSI DI INDONESIA</Title>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="a">ALL</Radio.Button>
                      <Radio.Button value="b">Provinsi</Radio.Button>
                      <Radio.Button value="c">Kab/Kota</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>PROVINSI</th>
                      <th>HIJAU</th>
                      <th>KUNING</th>
                      <th>MERAH</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>{1}</td>
                        <td>
                          <h6>
                            {region}
                          </h6>
                        </td>
                        <td>{dataProv[0]}</td>
                        <td>{dataProv[1]}</td>
                        <td>{dataProv[2]}</td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            Hijau
                          </span>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
            <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>COMPANIES</th>
                      <th>PROVINSI</th>
                      <th>KABUPATEN / KOTA</th>
                      <th>SCORE</th>
                      <th>COMPLETION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ranking.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <h6>
                            {d.nama_perusahaan}
                          </h6>
                        </td>
                        <td>{d.provinsi}</td>
                        <td>{d.kota}</td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.score}{" "}
                          </span>
                        </td>
                        <td>
                          <div className="percent-progress">
                            <Progress percent={d.score} size="small" 
                            strokeColor={d.score < 66 ? "red" : "yellow" || d.score > 79 ? "green" : "yellow" 
                            || d.score > 65 && d.score <= 80 ? "yellow" : "red"}/>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
