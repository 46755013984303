/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/kemnaker.png";
import { AiOutlineSetting } from "react-icons/ai";
import { useState } from "react";
import Admin from "./AdminRole";
import Staff from "./StafRole";

function Sidenav() {
  const [region, setRegion] = useState(localStorage.getItem("regional"));
  const [level, setLevel] = useState(localStorage.getItem("level"));
  const [superAdmin, setSuperAdmin] = useState(false);
  const [adminProvinsi, setAdminProvinsi] = useState(false);
  const [scope, setScope] = useState(false);



  return (
    <>
      {region === "All" && level === "Super Admin" || level === "Admin Pusat" ? (
          <Admin />
      ) : (
          <Staff />
      )}
    </>
  );
}

export default Sidenav;
