const eChart = {
  series: [{
    data: [22, 10, 20]
  }],
  options: {
    chart: {
      height: 250,
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    colors: ["#2EDB33","#EFE80B","#F20428"],
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        ['Perusahaan', 'Label Hijau'],
        ['Perusahaan', 'Label Kuning'],
        ['Perusahaan', 'Label Merah'],
      ],
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  },
};

export default eChart;
