/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import React, {useState, useContext} from 'react';
import { UserContext } from "./hooks/userContext";
import Home from "./pages/Home";
import Rekapitulasi from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Quiz from "./pages/Quiz";
import FormQuiz from "./pages/formQuiz";
import FormIsian from "./pages/FormIsian";
import Main from "./components/layout/Main";
import Result from "./pages/Result";
import User from "./pages/tableData/user";
import FormPartisipan from "./pages/FormPartisipan";
import Perusahaan from "./pages/tableData/perusahaan";
import Kuisioner from "./pages/tableData/kuisioner";
import TableFormQuiz from "./pages/tableData/tabelFormQuiz";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AddFormQuiz from "./pages/addFormQuiz";
import Pengaturan from "./pages/Pengaturan";
import EditUserProv from "./components/layout/FormEditProv";
import Wlkp from "./pages/tableData/wlkp";
import WlkpProv from "./pages/tableData/wlkpProv";

function App() {
  const [message, setMessage] = useState("");
  const [token] = useContext(UserContext);

  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/form-perusahaan" exact component={FormIsian} />
        <Route path="/kuisioner" exact component={Quiz} />
        <Route path="/result" exact component={Result} />
        <Route path="/form-karyawan" exact component={FormPartisipan} />
        {!token ? (
            <React.Fragment>
              <Route path="/login" exact component={SignIn} />
              <Redirect to="/login" />
            </React.Fragment>
        ) : (
          <Main>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/data-perusahaan" component={Perusahaan} />
            <Route exact path="/kuis" component={Kuisioner} />
            <Route exact path="/sub-soal/:id" component={TableFormQuiz} />
            <Route exact path="/form-quiz/:id" component={FormQuiz} />
            <Route exact path="/rekapitulasi" component={Rekapitulasi} />
            <Route exact path="/wlkp" component={Wlkp} />
            <Route exact path="/wlkp-propinsi" component={WlkpProv} />
            <Route exact path="/user" component={User} />
            <Route exact path="/pengaturan" component={Pengaturan} />
            <Route exact path="/buat-subsoal" component={AddFormQuiz} />
            <Route exact path="/edituser-provinsi" component={EditUserProv} />
          </Main>
        )}
      </Switch>
    </div>
  );
}

export default App;
