import { Table, Card, Col, Row, Typography, Space, Divider, Button, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import { endpoint } from '../../utils/endpoint';
import moment from 'moment';

const { Title } = Typography;
const columns = [
  {
    title: 'NO',
    dataIndex: 'No',
    width: '1%',
    key: "No"
  },
  {
    title: 'SOAL',
    dataIndex: 'soal',
    width: '10%',
    key: "soal"
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    width: '20%',
    key: "action"
  },
];

const TableFormQuiz = () => {
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const getToken = localStorage.getItem("tokens");
  const [token, setToken] = useState(getToken || null);
  const [dataSub, setDataSub] = useState([]);
  const params = useParams();

  const getData = async () => {
    setLoading(true)
    await axios.get(`${endpoint}/kuisioner/all_data_quiz/${parseInt(params.id)}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((res) => {
        setLoading(false);
        setTotalPages(res.data.length);
        console.log(res.data);
        res.data.map((data) => setDataSub(data.sub_soal));
    }).catch(err => console.log(err));
}

useEffect(() => {
    getData();
}, []);

  console.log(dataSub);

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const deletebtn = [
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
        fill="#111827"
        className="fill-danger"
      ></path>
    </svg>,
  ];

  const data = dataSub.map((d, i) => (
    {
      No: (
        <>
            <div className="avatar-info">
              <Title level={5}>{i+1}</Title>
            </div>
        </>
      ),
      soal: (
        <>
            <div className="avatar-info">
              <Title level={5}>{d.sub_soal}</Title>
            </div>
        </>
      ),
      action: (
        <>
            <div className="avatar-info">
            <Space direction="vertical">
            <Space wrap>
            <Button type="link" className="darkbtn" href={`/form-quiz/${d.id}`}>
              {pencil} EDIT
            </Button>
            <Button type="link" danger>
              {deletebtn}DELETE
            </Button>
            </Space>
          </Space>
            </div>
        </>
      )
    }
  ))

  return (
    <div className="tabled">
         <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="TABEL SUB SOAL"
              extra={
                  <>
                    <Radio.Group defaultValue="a">
                    <Button type="primary" href={`/buat-subsoal`}>
                        Buat Sub Soal
                      </Button>
                    </Radio.Group>
                  </>
              }
            >
            <div className='table-responsive'>
                <Table
                columns={columns}
                // rowKey={(record) => record.login.uuid}
                dataSource={data}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  total: totalPages,
                  onChange: (page) => {
                    getData(page);
                  },
                }}
                />
            </div>
            </Card>
            </Col>
         </Row>
    </div>
  );
};
export default TableFormQuiz;