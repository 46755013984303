import React from 'react';
import 'antd/dist/antd.css';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Select,
  Upload,
  Row,
  Col,
  message
} from 'antd';
import AppBar from '@mui/material/AppBar';
import logo from '../../assets/images/naker.png';
import logo2 from '../../assets/images/404.png';
import {Card, CardContent, Typography, List, Radio, RadioGroup, FormControl, Checkbox, Toolbar,Avatar,
  Box, Stack, Grid, ButtonGroup, LinearProgress, FormControlLabel, CardHeader, CardActionArea, CardActions} from "@mui/material";

const PageNotFound = () => {
    return (
        <>
        <Grid container wrap="nowrap" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={10}>
                <Card sx={{ boxShadow: 3, width: '100%', margin: 0, marginTop: '35px'}}>
                <br />
                <CardContent>
                <Stack direction="row" style={{ justifyContent: "center", display: "flex" }} spacing={0}>
                    <Avatar alt="Remy Sharp" src={logo2} 
                    sx={{ width: '55%', height: '50%'}} />
                </Stack>
                <br />
                <Typography variant="h3" style={{textAlign: 'center'}}>Isi form terlebih dahulu</Typography>
                </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
    )
}

export default PageNotFound