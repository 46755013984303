/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Input,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Tag,
  AutoComplete
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import React, { useEffect, useState} from "react";
import { endpoint } from "../utils/endpoint";
import axios from "axios";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {isEmpty} from 'lodash';
import logo from '../assets/images/Kementerian_Ketenagakerjaan.png';
// Images

const { Title } = Typography;

// table code start

// project table start  
function Rekapitulasi() {
  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const [get_data, setGetData] = useState([]);
  const [Partisipan, setPartisipan] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const getToken = localStorage.getItem("tokens");
  const [token, setToken] = useState(getToken || null);
  let [filteredData] = useState();
  let [filteredData2] = useState();
  const [cari, setCari] = useState("");

  const dataCompany = async () => {
      setLoading(true);
      await axios.get(`${endpoint}/company/data_perusahaan`)
      .then((res) => {
          setGetData(res.data);
          setTotalPages(res.data.length);
          setLoading(false);
      }).catch((err) => console.log(err));
  }

  useEffect(() => {
      dataCompany()
  }, []);

  const dataPartisipan = async () => {
      setLoading(true);
      await axios.get(`${endpoint}/partisipan/data_partisipan_all`)
      .then((res) => {
          setPartisipan(res.data);
          setTotalPages(res.data.length);
          setLoading(false);
      }).catch((err) => console.log(err));
  }

  useEffect(() => {
      dataPartisipan()
  }, []);

  const dataWithScore = get_data.map((item) => ({
     ...item
  }));

  const modifiedData = dataWithScore.map(({body, ...item}) => ({
      ...item,
      key: item.id,
      message: isEmpty(body) ? item.message: body,
  }));

  const dataPartisip = Partisipan.map((item) => ({
      ...item
  }));

  const modifiedData2 = dataPartisip.map(({body, ...item}) => ({
      ...item,
      key: item.id,
      message: isEmpty(body) ? item.message: body,
  }));

  const columns = [
    {
      title: 'NO',
      dataIndex: 'No',
      width: '1%',
      key: "No"
    },
    {
      title: "NAMA PERUSAHAAN",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ALAMAT",
      dataIndex: "function",
      key: "function",
      width: "20%",
    },
    {
      title: "PROVINSI",
      dataIndex: "provinsi",
      key: "provinsi",
    },
    {
      title: "KABUPATEN / KOTA",
      dataIndex: "kabkota",
      key: "kabkota",
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "WAKTU_UJIAN",
      key: "waktu_ujian",
      dataIndex: "waktu_ujian",
    },
  ];

  const columns2 = [
    {
      title: 'NO',
      dataIndex: 'No',
      width: '1%',
      key: "No"
    },
    {
      title: "NAMA",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "JABATAN",
      dataIndex: "jabatan",
      key: "jabatan",
      width: "20%",
    },
    {
      title: "PERUSAHAAN",
      dataIndex: "perusahaan",
      key: "perusahaan",
    },
    {
      title: "JENIS KELAMIN",
      dataIndex: "jenis_kelamin",
      key: "jenis_kelamin",
    },
    {
      title: "SCORE",
      dataIndex: "Score",
      key: "Score",
    },
  ];

  console.log(get_data);

  const data = get_data.map((d, index) => (
    {
      key: `${index+1}`,
      No: (
        <>
            <div className="avatar-info">
              <Title level={5}>{index+1}</Title>
            </div>
        </>
      ),
      name: (
        <>
            <div className="avatar-info">
              <Title level={5}>{d.nama_perusahaan}</Title>
            </div>
        </>
      ),
      function: (
        <>
          <div className="author-info">
            <Title level={5}>{d.alamat}</Title>
            <p>Organization</p>
          </div>
        </>
      ),

      provinsi: (
        <>
          <div className="author-info">
            <Title level={5}>{d.provinsi}</Title>
          </div>
        </>
      ),

      kabkota: (
        <>
          <div className="author-info">
            <Title level={5}>{d.kabkota}</Title>
          </div>
        </>
      ),
  
      status: (
        <>
        <p>Score: {d.score}</p>
        {d.score < 66
          ?
            <Tag color="error">LABEL MERAH</Tag>
          : [
            d.score > 65 && d.score < 80
            ?
            <Tag color="warning">LABEL KUNING</Tag>
            :
            <Tag color="success">LABEL HIJAU</Tag>
          ]
        }
        </>
      ),
      waktu_ujian: (
        <>
          <div className="ant-employed">
            <span>{moment(d.penilaian.waktu_selesai).format("D MMMM YYYY")}</span>
          </div>
        </>
      ),
    }
  ))

  const partisipan = Partisipan.map((d, index) => (
    {
      key: `${index+1}`,
      No: (
        <>
            <div className="avatar-info">
              <Title level={5}>{index+1}</Title>
            </div>
        </>
      ),
      nama: (
        <>
            <div className="avatar-info">
              <Title level={5}>{d.nama}</Title>
            </div>
        </>
      ),
      jabatan: (
        <>
          <div className="author-info">
            <Title level={5}>{d.jabatan}</Title>
            <p>Organization</p>
          </div>
        </>
      ),

      perusahaan: (
        <>
          <div className="author-info">
            <Title level={5}>{d.perusahaan}</Title>
          </div>
        </>
      ),

      jenis_kelamin: (
        <>
          <div className="author-info">
            <Title level={5}>{d.jenis_kelamin}</Title>
          </div>
        </>
      ),
      Score: (
        <>
          <div className="author-info">
            <Title level={5}>{d.score}</Title>
          </div>
        </>
      ),
    }
  ))

  const downloadPDF = () => {
    const doc = new jsPDF();
    let body = []
    // generate auto table with body
    get_data.forEach((elem, i, arr) => {
      if(elem.score >= 79.99) {
        let status = "Hijau"
        body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
      } else if(elem.score < 80 && elem.score > 65) {
        let status = "Kuning"
        body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
      } else if(elem.score < 66) {
        let status = "Merah"
        body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
      }
    })
    var header = function (data) {
      doc.addImage(logo, 'PNG', data.settings.margin.left, 2, 45, 50);
      doc.setFontSize(11);
    };
    doc.setLineWidth(2);
    doc.autoTable({
      beforePageContent: header,
      head: [["No.","Nama Perusahaan","Alamat","Provinsi","Kabupaten / Kota", "Nilai", "Status Perusahaan"]],
      startY: 50,
      body: body,
      theme: 'grid',
    })
    // save the data to this file
    doc.save('data_perusahaan');
  }

  const downloadExcel = () => {
      const workSheet = XLSX.utils.json_to_sheet(get_data)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, "Perusahaan")
      let buf = XLSX.write(workBook,{bookType: "xlsx", type: "buffer"})
      XLSX.write(workBook,{bookType: "xlsx", type: "binary"})
      XLSX.writeFile(workBook,"perusahaan.xlsx")
  }

  const handleChange = (...sorted) => {
      const {order,field} = sorted[2];
      setSortedInfo({columnKey: field, order})
  }

  const handleInputChange = (e) => {
    setCari(e.target.value)
    if(e.target.value === "") {
        dataCompany();
    } 
  };

  const handleChange2 = (...sorted) => {
    const {order,field} = sorted[2];
    setSortedInfo({columnKey: field, order})
  }

  const handleInputChange2 = (e) => {
    setCari(e.target.value)
    if(e.target.value === "") {
        dataPartisipan();
    } 
  };

  const globalSearch = () => {
      filteredData = modifiedData.filter((value) => {
          return(
              value.nama_perusahaan.toLowerCase().includes(cari.toLowerCase()) ||
              value.alamat.toLowerCase().includes(cari.toLowerCase()) ||
              value.provinsi.toLowerCase().includes(cari.toLowerCase()) ||
              value.kabkota.toLowerCase().includes(cari.toLowerCase())
          );
      });
      setGetData(filteredData)
  };

  const globalSearch2 = () => {
    filteredData2 = modifiedData2.filter((value) => {
        return(
            value.nama.toLowerCase().includes(cari.toLowerCase()) ||
            value.jabatan.toLowerCase().includes(cari.toLowerCase()) ||
            value.perusahaan.toLowerCase().includes(cari.toLowerCase()) ||
            value.jenis_kelamin.toLowerCase().includes(cari.toLowerCase())
        );
    });
    setPartisipan(filteredData2)
};

  return (
    <>
    <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Tabel Penilaian Perusahaan"
              extra={
                <>
                  <Input style={{ width: 280 }} placeholder="Cari..." 
                    type="text"
                    onChange={handleInputChange}
                    value={cari}
                    /> 
                  <Radio.Group onChange={onChange} defaultValue="a">
                    <Button onClick={globalSearch} type="primary">Cari</Button>
                    <Button onClick={downloadPDF}>PDF</Button>
                    <Button onClick={downloadExcel} success>Excel</Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredData && filteredData.length ? filteredData : data}
                  loading={loading}
                  onChange={handleChange}
                  pagination={{
                      pageSize: 10,
                      total: totalPages,
                      onChange: (page) => {
                        dataCompany(page);
                      },
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Tabel Penilaian Karyawan"
              extra={
                <>
                  <Input style={{ width: 280 }} placeholder="Cari..." 
                    type="text"
                    onChange={handleInputChange2}
                    value={cari}
                    /> 
                  <Radio.Group onChange={onChange} defaultValue="a">
                    <Button onClick={globalSearch2} type="primary">Cari</Button>
                    <Button onClick={downloadPDF}>PDF</Button>
                    <Button onClick={downloadExcel} success>Excel</Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns2}
                  dataSource={filteredData2 && filteredData2.length ? filteredData2 : partisipan}
                  loading={loading}
                  onChange={handleChange2}
                  pagination={{
                      pageSize: 10,
                      total: totalPages,
                      onChange: (page) => {
                        dataPartisipan(page);
                      },
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Rekapitulasi;
