/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Space
  } from "antd";
  
  import { ToTopOutlined } from "@ant-design/icons";
  import { Link } from "react-router-dom";
  import React, { useEffect, useState} from "react";
  import { endpoint } from "../utils/endpoint";
  import axios from "axios";
  
  // Images
  import ava1 from "../assets/images/logo-shopify.svg";
  import ava2 from "../assets/images/logo-atlassian.svg";
  import ava3 from "../assets/images/logo-slack.svg";
  import ava5 from "../assets/images/logo-jira.svg";
  import ava6 from "../assets/images/logo-invision.svg";
  import face from "../assets/images/face-1.jpg";
  import face2 from "../assets/images/face-2.jpg";
  import face3 from "../assets/images/face-3.jpg";
  import face4 from "../assets/images/face-4.jpg";
  import face5 from "../assets/images/face-5.jpeg";
  import face6 from "../assets/images/face-6.jpeg";
  import pencil from "../assets/images/pencil.svg";
  
  const { Title } = Typography;
  
  const formProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  // table code start
  const columns = [
    {
      title: "NAMA PERUSAHAAN",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
    {
      title: "ALAMAT",
      dataIndex: "function",
      key: "function",
    },
    {
      title: "PROVINSI",
      dataIndex: "provinsi",
      key: "provinsi",
    },
    {
      title: "KABUPATEN / KOTA",
      dataIndex: "kabkota",
      key: "kabkota",
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "EMPLOYED",
      key: "employed",
      dataIndex: "employed",
    },
  ];
  
  // project table start
  const project = [
    {
      title: "LINK COPY",
      dataIndex: "name",
      width: "3%",
    },
    {
      title: "",
      dataIndex: "form",
    },
  ];
  function Pengaturan() {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [get_data, setGetData] = useState([])
    const [copyLink1, setCopyLink1] = useState('https://penilaian-hi.kemnaker.go.id/form-perusahaan')
    const [copyLink2, setCopyLink2] = useState('https://penilaian-hi.kemnaker.go.id/form-karyawan')

    const handleCopy = () => {
        navigator.clipboard.writeText(copyLink1);
        alert("Coppied");
    };

    const handleCopy2 = () => {
      navigator.clipboard.writeText(copyLink2);
      alert("Coppied");
  };

    const dataproject = [
      {
        key: "1",
        name: (
          <>
            <Avatar.Group>
              <span><h6>Link Form Perusahaan: </h6></span>
            </Avatar.Group>
          </>
        ),
        form: (
          <>
            <Avatar.Group>
              <Space />
              <div style={{display: 'block', width: 500}} className="avatar-info">
                <Input value={copyLink1} onChange={(e) => setCopyLink1(e.target.value)} readOnly/>
                <span>
                    <Button onClick={handleCopy} type="primary" className="tag-primary">Copy</Button>
                </span>
              </div>
            </Avatar.Group>
          </>
        ),
      },
    
      {
        key: "2",
        name: (
          <>
            <Avatar.Group>
              <span><h6>Link Form Karyawan / Pekerja: </h6></span>
            </Avatar.Group>
          </>
        ),
        form: (
          <>
            <Avatar.Group>
              <div style={{display: 'block', width: 500}} className="avatar-info">
              <Input value={copyLink2} onChange={(e) => setCopyLink2(e.target.value)} readOnly/>
                <span>
                    <Button onClick={handleCopy2} type="primary" className="tag-primary">Copy</Button>
                </span>
              </div>
            </Avatar.Group>
          </>
        ),
      },
    ];
  
    const dataCompany = async () => {
        await axios.get(`${endpoint}/company/data_perusahaan`)
        .then((res) => {
            setGetData(res.data)
            console.log(res.data)
        }).catch((err) => console.log(err))
    }
  
    useEffect(() => {
        dataCompany()
    }, [])
  
    const data = [
      {
        key: "1",
        name: (
          <>
            <Avatar.Group>
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face2}
              ></Avatar>
              <div className="avatar-info">
                <Title level={5}>Michael John</Title>
                <p>michael@mail.com</p>
              </div>
            </Avatar.Group>{" "}
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>Manager</Title>
              <p>Organization</p>
            </div>
          </>
        ),
    
        status: (
          <>
            <Button type="primary" className="tag-primary">
              ONLINE
            </Button>
          </>
        ),
        employed: (
          <>
            <div className="ant-employed">
              <span>23/04/18</span>
              <a href="#pablo">Edit</a>
            </div>
          </>
        ),
      },
    
      {
        key: "2",
        name: (
          <>
            <Avatar.Group>
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face3}
              ></Avatar>
              <div className="avatar-info">
                <Title level={5}>Alexa Liras</Title>
                <p>alexa@mail.com</p>
              </div>
            </Avatar.Group>{" "}
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>Programator</Title>
              <p>Developer</p>
            </div>
          </>
        ),
    
        status: (
          <>
            <Button className="tag-badge">ONLINE</Button>
          </>
        ),
        employed: (
          <>
            <div className="ant-employed">
              <span>23/12/20</span>
              <a href="#pablo">Edit</a>
            </div>
          </>
        ),
      },
    
      {
        key: "3",
        name: (
          <>
            <Avatar.Group>
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face}
              ></Avatar>
              <div className="avatar-info">
                <Title level={5}>Laure Perrier</Title>
                <p>laure@mail.com</p>
              </div>
            </Avatar.Group>{" "}
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>Executive</Title>
              <p>Projects</p>
            </div>
          </>
        ),
    
        status: (
          <>
            <Button type="primary" className="tag-primary">
              ONLINE
            </Button>
          </>
        ),
        employed: (
          <>
            <div className="ant-employed">
              <span>03/04/21</span>
              <a href="#pablo">Edit</a>
            </div>
          </>
        ),
      },
      {
        key: "4",
        name: (
          <>
            <Avatar.Group>
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face4}
              ></Avatar>
              <div className="avatar-info">
                <Title level={5}>Miriam Eric</Title>
                <p>miriam@mail.com</p>
              </div>
            </Avatar.Group>{" "}
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>Marketing</Title>
              <p>Organization</p>
            </div>
          </>
        ),
    
        status: (
          <>
            <Button type="primary" className="tag-primary">
              ONLINE
            </Button>
          </>
        ),
        employed: (
          <>
            <div className="ant-employed">
              <span>03/04/21</span>
              <a href="#pablo">Edit</a>
            </div>
          </>
        ),
      },
      {
        key: "5",
        name: (
          <>
            <Avatar.Group>
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face5}
              ></Avatar>
              <div className="avatar-info">
                <Title level={5}>Richard Gran</Title>
                <p>richard@mail.com</p>
              </div>
            </Avatar.Group>{" "}
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>Manager</Title>
              <p>Organization</p>
            </div>
          </>
        ),
    
        status: (
          <>
            <Button className="tag-badge">ONLINE</Button>
          </>
        ),
        employed: (
          <>
            <div className="ant-employed">
              <span>23/03/20</span>
              <a href="#pablo">Edit</a>
            </div>
          </>
        ),
      },
    
      {
        key: "6",
        name: (
          <>
            <Avatar.Group>
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face6}
              ></Avatar>
              <div className="avatar-info">
                <Title level={5}>John Levi</Title>
                <p>john@mail.com</p>
              </div>
            </Avatar.Group>{" "}
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>Tester</Title>
              <p>Developer</p>
            </div>
          </>
        ),
    
        status: (
          <>
            <Button className="tag-badge">ONLINE</Button>
          </>
        ),
        employed: (
          <>
            <div className="ant-employed">
              <span>14/04/17</span>
              <a href="#pablo">Edit</a>
            </div>
          </>
        ),
      },
    ];
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Tautan"
              >
                <div className="table-responsive">
                  <Table
                    columns={project}
                    dataSource={dataproject}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default Pengaturan;
  