import { usePromiseTracker } from "react-promise-tracker";
import {Bars} from 'react-loader-spinner';


export const LoaderIndicators = () => {
    const {promiseInProgress} = usePromiseTracker();
    return(
        promiseInProgress && (
        <div>
            <Bars
                height="125"
                width="1000"
                color="#15406A"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
       )
    )
}