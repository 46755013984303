/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography, Button, Form, Skeleton, Select, Space } from "antd";
import eChart from "./configs/eChart";
import React, {useState, useEffect} from 'react';
import axios from "axios";
import { endpoint } from "../../utils/endpoint";

function EChart() {
  const { Title, Paragraph } = Typography;
  const [datas, setDatas] = useState([]);
  const [datas2, setDatas2] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [dataKota, setDataKota] = useState([]);
  const [ids, setIds] = useState(0);
  const [idP, setIdP] = useState("");
  const [namaProvinsi, setNamaProvinsi] = useState("All");
  const [namaKota, setNamaKota] = useState("");
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(localStorage.getItem("regional"));
  const [level, setLevel] = useState(localStorage.getItem("level"));

  const getProvinsi = async () => {
    setLoading(true);
    await axios.get(`${endpoint}/lokasi/`)
    .then((res) => {
      setProvinsi(res.data.data);
      console.log(res.data.data);
      setLoading(false);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getProvinsi();
  }, []);

  const getKota = async (id) => {
    await axios.get(`${endpoint}/lokasi/${id}`)
    .then((res) => {
      setDataKota(res.data.data);
      console.log(res.data.data);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getKota();
  }, [ids]);

  const getData = async (e) => {
      if(region === "All" && e === undefined) {
        e = "All";
        console.log(e);
      } else if (region !== "All" && e === undefined) {
        e = region;
        console.log(e);
      }
      setLoading(true);
      await axios.get(`${endpoint}/data_driven/statistika/${e}`)
      .then((res) => {
        setLoading(false);
        setDatas2(res.data)
        console.log(res.data);
        console.log(e);
        if(provinsi.length !== 0) {
          let province = provinsi.filter(data => data.name === e);
          let getProv = province.map(data => data.id);
          let id = Number(getProv);
          getKota(id);
          console.log(provinsi);
        }
      }).catch(err => console.log(err));
  }

  useEffect(() => {
    getData();
  }, []);

  const getDataKabupaten = async (e) => {
    setLoading(true);
    await axios.get(`${endpoint}/data_driven/statistika_kabupaten/${e}`)
    .then((res) => {
      setLoading(false);
      setDatas2(res.data)
      console.log(res.data);
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    getDataKabupaten();
  }, []);

  // const getAll = async () => {
  //   setLoading(true);
  //   await axios.get(`${endpoint}/data_driven/label_statistik`)
  //   .then((res) => {
  //     setLoading(false);
  //     setDatas(res.data);
  //     console.log(res.data);
  //   }).catch(err => console.log(err));
  // };

  // useEffect(() => {
  //     getAll();
  // }, []);

  // const sum = datas.reduce((accumulator, value) => {
  //   return accumulator + value;
  // }, 0);

  const sum2 = datas2.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const items = [
    {
      Title: sum2,
      user: "All",
    },
    {
      Title: datas2[0],
      user: "Berlabel Hijau",
    },
    {
      Title: datas2[1],
      user: "Berlabel Kuning",
    },
    {
      Title: datas2[2],
      user: "Berlabel Merah",
    },
  ];

  const { Option } = Select;
  console.log(datas2);
  console.log(items);

  return (
    <>
    <Skeleton loading={loading}>
      <div id="chart">
        <ReactApexChart
          options={eChart.options}
          series={ 
          [{
            data: datas2
          }]

          }
          type="bar"
          height={250}
          loading={loading}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>DATA: {namaProvinsi}</Title>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <br />
      </Skeleton>
      <Row type="flex" justify="center" align="middle">
        {region === "All" ? (
            <Space wrap>
            <Select style={{width: "230px"}} onChange={(e) => {
                    getData(e)
                    setNamaProvinsi(e)
            }} placeholder="Provinsi">
              <Option value="All">KESELURUHAN</Option>
              {provinsi.map((data) => (
                  <Option value={data.name}>{data.name}</Option>
              ))}
            </Select>
  
            <Select style={{width: "230px"}}
                placeholder="Kabupaten / Kota"
                onChange={(e) => {
                  getDataKabupaten(e)
                }}
                >
                {dataKota.map((kota, i) => (
                  <Option key={i} value={kota.name}>{kota.name}</Option>
                ))}
            </Select>
            </Space>
        ) : (
          <Space wrap>
            <Select style={{width: "230px"}}
              placeholder="Provinsi"
              onChange={(e) => {
                getData(e)
              }}
              >
                <Option value={region}>{region}</Option>
          </Select>
          <Select style={{width: "230px"}}
              placeholder="Kabupaten / Kota"
              onChange={(e) => {
                getDataKabupaten(e)
              }}
              >
              {dataKota.map((kota, i) => (
                <Option key={i} value={kota.name}>{kota.name}</Option>
              ))}
          </Select>
          </Space>
        )}
      </Row>
    </>
  );
}

export default EChart;
