import 'antd/dist/antd.css';
import {
  Button,
  Form,
  Input,
  Select,
  notification,
  Alert, 
  Space,
  Upload,
  Typography, 
  Row,
  Col,
  Tooltip,
  message,
  Card
} from 'antd';
import AppBar from '@mui/material/AppBar';
import logo from '../assets/images/naker.png';
import {CardContent, List, Radio, RadioGroup, FormControl, Checkbox, Toolbar,Avatar,
  Box, Stack, Grid, ButtonGroup, LinearProgress, FormControlLabel, CardHeader, CardActionArea, CardActions} from "@mui/material";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React from "react";
import axios from "axios";
import {useState, useEffect} from "react";
import { endpoint } from "../utils/endpoint";
import { useHistory } from 'react-router-dom';
// import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import {useParams} from 'react-router-dom';
  
export default function FormQuiz() {

    const redirect = useHistory();
    const getToken = localStorage.getItem("tokens");
    const [token, setToken] = useState(getToken || null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [subSoal, setSubsoal] = useState("");
    const [subJawaban, setSubJawaban] = useState("");
    const [subBobot, setSubbobot] = useState(0);
    const [typeQuiz, settypeQuiz] = useState("");
    const [jawabanQ1, setJawabanQ1] = useState("");
    const [jawabanQ2, setJawabanQ2] = useState("");
    const [jawabanQ3, setJawabanQ3] = useState("");
    const [jawabanQ4, setJawabanQ4] = useState("");
    const [jawabanQ5, setJawabanQ5] = useState("");
    const [jawabanQ6, setJawabanQ6] = useState("");
    const [jawabanQ7, setJawabanQ7] = useState("");
    const [jawabanQ8, setJawabanQ8] = useState("");
    const [jawabanQ9, setJawabanQ9] = useState("");
    const [jawabanQ10, setJawabanQ10] = useState("");
    const [jawabanQ11, setJawabanQ11] = useState("");
    const [jawabanQ12, setJawabanQ12] = useState("");
    const [bobotQ1, setBobotQ1] = useState(0);
    const [bobotQ2, setBobotQ2] = useState(0);
    const [bobotQ3, setBobotQ3] = useState(0);
    const [bobotQ4, setBobotQ4] = useState(0);
    const [bobotQ5, setBobotQ5] = useState(0);
    const [bobotQ6, setBobotQ6] = useState(0);
    const [bobotQ7, setBobotQ7] = useState(0);
    const [bobotQ8, setBobotQ8] = useState(0);
    const [bobotQ9, setBobotQ9] = useState(0);
    const [bobotQ10, setBobotQ10] = useState(0);
    const [bobotQ11, setBobotQ11] = useState(0);
    const [bobotQ12, setBobotQ12] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };

    const useStyles = makeStyles((theme) => ({
      appBar: {
        top: "auto",
        bottom: 0
      },
      typo: {
        flexGrow: 1,
        textAlign: "center"
      }
    }));

    const classes = useStyles();

    const { Option } = Select;
    const openNotificationSucces = () => {
      api.success({
        message: `Update Berhasil`,
        description:
          ""
      });
    };

    const openNotificationError = (message) => {
      api.error({
        message: `Update Gagal`,
        description:
          message
      });
    };

    const helps = [
      <Tooltip title="Useful information">
        <Typography.Link href="#API">Need Help?</Typography.Link>
      </Tooltip>
    ]
    
    const update = async () =>{
      await axios.put(`${endpoint}/kuisioner/edit_subquiz/${parseInt(params.id)}`, {
        "sub_soal": subSoal,
        "subbab_soal": [],
        "sub_jawaban": subJawaban,
        "q1": jawabanQ1,
        "q2": jawabanQ2,
        "q3": jawabanQ3,
        "q4": jawabanQ4,
        "q5": jawabanQ5,
        "q6": jawabanQ6,
        "q7": jawabanQ7,
        "q8": jawabanQ8,
        "q9": jawabanQ9,
        "q10": jawabanQ10,
        "q11": jawabanQ11,
        "q12": jawabanQ12,
        "q13": "string",
        "sub_q": [],
        "bobot_subjawaban": subBobot,
        "bobot1": bobotQ1,
        "bobot2": bobotQ2,
        "bobot3": bobotQ3,
        "bobot4": bobotQ4,
        "bobot5": bobotQ5,
        "bobot6": bobotQ6,
        "bobot7": bobotQ7,
        "bobot8": bobotQ8,
        "bobot9": bobotQ9,
        "bobot10": bobotQ10,
        "bobot11": bobotQ11,
        "bobot12": bobotQ12,
        "bobot13": 0,
        "tipe": typeQuiz,
        "quiz_id": params.id
      } ). then(()=>
        openNotificationSucces()
      ).catch(err => err.message =="getPrefixCls is not a function"?console.log("Error data belum di ubah"):openNotificationError(err.message))
    }

    const getData = async () => {
      await axios.get(`${endpoint}/kuisioner/get_subquiz/${parseInt(params.id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then((res) => {
          setLoading(false);
          setSubsoal(res.data[0].sub_soal);
          setSubJawaban(res.data[0].sub_jawaban);
          setSubbobot(res.data[0].bobot_subjawaban);
          settypeQuiz(res.data[0].tipe);
            //  console.log(res.data);
            //  console.log(res.data[0].jawaban[1]);
          setJawabanQ1(res.data[0].jawaban[0]);
          setJawabanQ2(res.data[0].jawaban[1])
          setJawabanQ3(res.data[0].jawaban[2]);;
          setJawabanQ4(res.data[0].jawaban[3]);
          setJawabanQ5(res.data[0].jawaban[4]);
          setJawabanQ6(res.data[0].jawaban[5]);
          setJawabanQ7(res.data[0].jawaban[6]);
          setJawabanQ8(res.data[0].jawaban[7]);
          setJawabanQ9(res.data[0].jawaban[8]);
          setJawabanQ10(res.data[0].jawaban[9]);
          setJawabanQ11(res.data[0].jawaban[10]);
          setJawabanQ12(res.data[0].jawaban[11]);

         setBobotQ1(res.data[0].bobot[0]);
         setBobotQ2(res.data[0].bobot[1])
         setBobotQ3(res.data[0].bobot[2]);;
         setBobotQ4(res.data[0].bobot[3]);
         setBobotQ5(res.data[0].bobot[4]);
         setBobotQ6(res.data[0].bobot[5]);
         setBobotQ7(res.data[0].bobot[6]);
         setBobotQ8(res.data[0].bobot[7]);
         setBobotQ9(res.data[0].bobot[8]);
         setBobotQ10(res.data[0].bobot[9]);
         setBobotQ11(res.data[0].bobot[10]);
         setBobotQ12(res.data[0].bobot[11]);
      }).catch(err => console.log(err));
    }

    const onFinish = (values) => {
      console.log('Received values of form:', values);
    };

    useEffect(() => {
      getData();
    }, []);
   
   return (
        <>
        {contextHolder}
        <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
        <Card sx={{ boxShadow: 3, width: '100%', margin: 0, marginTop: '10px', marginBottom: '10px' }}>
          <CardHeader action={""} />
          <Typography variant="h5" className={classes.typo}>Form Sub Soal</Typography>
          <br />
            <CardContent>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
              >
                <Form.Item
                  className="Form-label"
                  label="Sub Soal"
                >
                <Input
                  style={{ minWidth: 200, maxWidth: 500 }}
                  placeholder="Masukan Sub Sub Soal"
                  value={subSoal}
                  onChange={(e) => setSubsoal(e.target.value)}
                />
                </Form.Item>
                <Form.Item
                  className="Form-label"
                  label="Subbab Jawaban"
                >
                <Input
                  style={{ minWidth: 200, maxWidth: 500 }}
                  placeholder="Masukan Subbab Jawaban"
                  value={subJawaban}
                  onChange={(e) => setSubJawaban(e.target.value)}
                />
                </Form.Item>
                <Form.Item 
                  className="Form-label"
                  label = "Bobot"
                >
                <Input
                  placeholder="Masukan Sub Bobot"  
                  style={{ minWidth: 200, maxWidth: 500 }}
                  value={subBobot}
                  onChange={(e) =>  setSubbobot(e.target.value)}
                />
                </Form.Item>
                <Form.Item label = "A"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ1} onChange={(e) =>  setJawabanQ1(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <br />
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name"   />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name"  />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "B"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ2} onChange={(e) =>  setJawabanQ2(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "C"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ3} onChange={(e) =>  setJawabanQ3(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "D"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ4} onChange={(e) =>  setJawabanQ4(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "E"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ5} onChange={(e) =>  setJawabanQ5(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "F"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ6} onChange={(e) =>  setJawabanQ6(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "G"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ7} onChange={(e) =>  setJawabanQ7(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "H"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ8} onChange={(e) =>  setJawabanQ8(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "I"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ9} onChange={(e) =>  setJawabanQ9(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "J"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ10} onChange={(e) =>  setJawabanQ10(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "K"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ11} onChange={(e) =>  setJawabanQ11(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "L"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ12} onChange={(e) =>  setJawabanQ12(e.target.value)} 
                 />
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  style={{
                    maxWidth: 600,
                  }}
                  autoComplete="off"
                >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'first']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'last']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing last name',
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Space>
                      <Tooltip title="Useful information">
                        <Typography.Link onClick={() => add()}>Tambah Sub Jawaban</Typography.Link>
                      </Tooltip>
                      </Space>
                    </>
                  )}
                </Form.List>
                </Form>
                </Form.Item>
                <Form.Item label = "Bobot 1" className="Form-label">
                 <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ1} onChange={(e) =>  setBobotQ1(e.target.value)} />
                 </Form.Item> 
                 <Form.Item label = "Bobot 2"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ2} onChange={(e) =>  setBobotQ2(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 3"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ3} onChange={(e) =>  setBobotQ3(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 4"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ4} onChange={(e) =>  setBobotQ4(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 5"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ5} onChange={(e) =>  setBobotQ5(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 6"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ6} onChange={(e) =>  setBobotQ6(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 7"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ7} onChange={(e) =>  setBobotQ7(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 8"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ8} onChange={(e) =>  setBobotQ8(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 9"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ9} onChange={(e) =>  setBobotQ9(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 10"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ10} onChange={(e) =>  setBobotQ10(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 11"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ11} onChange={(e) =>  setBobotQ11(e.target.value)} /> </Form.Item>
                <Form.Item label = "Bobot 12"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ12} onChange={(e) =>  setBobotQ12(e.target.value)} /> </Form.Item>
                <Form.Item label="Pilih Type Kuis">
                  <Select
                    placeholder="Pilih Tipe Kuis"
                    id={typeQuiz}
                    value={typeQuiz}
                    onChange={(e) => {
                      settypeQuiz(e)
                    }}
                    >
                    <Option value= "Checkbox"> Pilih Banyak</Option>
                    <Option value= "Radio"> Pilih Salah Satu</Option>
                    <Option value= "Checkbox & Radio"> Kombinasi</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => update() }
                    style={{marginLeft: 155}}
                  >
                   Update
                  </Button>
                </Form.Item>     
              </Form>
            </CardContent>
          </Card>
        </Col>
        </Row>
          <br />
        </>
      );
    } 