import React from "react";

export default function resfreshPage() {
    const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
}
