/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Input,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Tag,
    AutoComplete
  } from "antd";
  import moment from "moment";
  import { Link } from "react-router-dom";
  import React, { useEffect, useState, useCallback, useRef} from "react";
  import { endpoint } from "../../utils/endpoint";
  import axios from "axios";
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable'
  import * as XLSX from 'xlsx';
  import {isEmpty} from 'lodash';
  import logo from '../../assets/images/Kementerian_Ketenagakerjaan.png';
  // Images
  
  const { Title } = Typography;
  
  // table code start
  
  // project table start  
  function Wlkp() {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [get_data, setGetData] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const getToken = localStorage.getItem("tokens");
    const [token, setToken] = useState(getToken || null);
    let [filteredData] = useState();
    const [cari, setCari] = useState("");
    const [region, setRegion] = useState(localStorage.getItem("regional"));
    const [level, setLevel] = useState(localStorage.getItem("level"));
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,  // Number of records per page
      total: 0,      // Total number of records, set from server response
    });
  
    const fetchData = async (page, pageSize) => {
      const currentPage = isNaN(page) ? 1 : page;
      const currentPageSize = isNaN(pageSize) ? 10 : pageSize;
      const offset = (currentPage - 1) * currentPageSize;
      setLoading(true);
      try {
        const response = await axios.get(`${endpoint}/data-wlkp/data-indonesia/`, {
          params: {
            offset: offset, // Calculate the "skip" value
            limit: pageSize,  // Pass "limit" as pageSize
          },
        });
  
        const newData = response.data;
        const totalRecords = 48392;  // Since we are simulating 1M records
  
        // Update the table data and pagination
        setGetData(newData);
        setPagination({
          ...pagination,
          current: page,
          pageSize,
          total: totalRecords,
        });
      } catch (error) {
        message.error(error.response.status);
      } finally {
        setLoading(false);
      }
    };
  
    // Handle table change (pagination, filters, sorting)
    const handleTableChange = (pagination) => {
      fetchData(pagination.current, pagination.pageSize);
    };
  
    // Fetch data when component mounts
    useEffect(() => {
      fetchData(pagination.current, pagination.pageSize);
    }, []);

    const columns = [
      {
        title: 'NO',
        dataIndex: 'No',
        width: '1%',
        key: "No"
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: "id"
      },
      // {
      //   title: 'Kode Pendaftaran',
      //   dataIndex: 'KODE PENDAFTARAN',
      //   key: "KODE PENDAFTARAN"
      // },
      // {
      //   title: 'PUSAT/CABANG',
      //   dataIndex: 'PUSAT/CABANG',
      //   key: "PUSAT/CABANG"
      // },
      // {
      //   title: 'TDP/NIB',
      //   dataIndex: 'TDP/NIB',
      //   key: "TDP/NIB"
      // },
      {
        title: "NAMA PERUSAHAAN",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "ALAMAT",
        dataIndex: "function",
        key: "function",
        width: "20%",
      },
      {
        title: "PROVINSI",
        dataIndex: "provinsi",
        key: "provinsi",
      },
      {
        title: "KABUPATEN / KOTA",
        dataIndex: "kabkota",
        key: "kabkota",
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
      },
    ];
  
    const data = get_data.map((d, index) => (
      {
        key: `${index+1}`,
        No: (
          <>
              <div className="avatar-info">
                <Title level={5}>{index+1}</Title>
              </div>
          </>
        ),
        id: (
          <>
              <div className="avatar-info">
                <Title level={5}>{d.id}</Title>
              </div>
          </>
        ),
        name: (
          <>
              <div className="avatar-info">
                <Title level={5}>{d.nama_perusahaan}</Title>
              </div>
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>{d.alamat}</Title>
              <p>Organization</p>
            </div>
          </>
        ),

        provinsi: (
          <>
            <div className="author-info">
              <Title level={5}>{d.propinsi}</Title>
            </div>
          </>
        ),

        kabkota: (
          <>
            <div className="author-info">
              <Title level={5}>{d.kab_kota}</Title>
            </div>
          </>
        ),
    
        status: (
          <>
          <p>{d.aktif__tutup}</p>
          </>
        ),
      }
    ))

    const downloadPDF = () => {
      const doc = new jsPDF();
      let body = []
      // generate auto table with body
      get_data.forEach((elem, i, arr) => {
        if(elem.score >= 79.99) {
          let status = "Hijau"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        } else if(elem.score < 80 && elem.score > 65) {
          let status = "Kuning"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        } else if(elem.score < 66) {
          let status = "Merah"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        }
      })
      var header = function (data) {
        doc.addImage(logo, 'PNG', data.settings.margin.left, 2, 45, 50);
        doc.setFontSize(11);
      };
      doc.setLineWidth(2);
      doc.autoTable({
        beforePageContent: header,
        head: [["No.","Nama Perusahaan","Alamat","Provinsi","Kabupaten / Kota", "Nilai", "Status Perusahaan"]],
        startY: 50,
        body: body,
        theme: 'grid',
      })
      // save the data to this file
      doc.save('data_perusahaan');
    }

    const handleInputChange = (e) => {
      setCari(e.target.value)
      if(e.target.value === "") {
          fetchData();
      } 
    };

    // const globalSearch = () => {
    //     filteredData = modifiedData.filter((value) => {
    //         return(
    //             value.nama_perusahaan.toLowerCase().includes(cari.toLowerCase()) ||
    //             value.alamat.toLowerCase().includes(cari.toLowerCase()) ||
    //             value.provinsi.toLowerCase().includes(cari.toLowerCase()) ||
    //             value.kabkota.toLowerCase().includes(cari.toLowerCase())
    //         );
    //     });
    //     setGetData(filteredData)
    // };
  
    return (
      <>
        <div className="tabled">
            <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Tabel Perusahaan"
                extra={
                  <>
                    <Input style={{ width: 280 }} placeholder="Cari..." 
                      type="text"
                      onChange={handleInputChange}
                      value={cari}
                      /> 
                    <Radio.Group onChange={onChange} defaultValue="a">
                      <Button onClick={downloadPDF}>PDF</Button>
                    </Radio.Group>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    onChange={handleTableChange}
                    pagination={pagination}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default Wlkp;
  