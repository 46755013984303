const lineChart = {
  series: [200, 150, 250],

  options: {
    labels: ["Hijau", "Kuning", "Merah"],
    colors: ["#2EDB33", "#EFE80B", "#F20428"],
    chart: { type: "donut" },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    fill: { colors: ["#2EDB33", "#EFE80B", "#F20428"] },
    stroke: { width: 0 },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: { show: true },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;

                const result = totals.reduce((a, b) => a + b, 0);
                // (result / 1000).toFixed(3)
                return result;
              }
            }
          }
        }
      }
    }
  }
};

export default lineChart;
