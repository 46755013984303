import React, {useState, useEffect, useContext, useRef} from 'react';
import {message} from 'antd';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Stack, CardActionArea } from '@mui/system';
import axios from 'axios';
import { endpoint } from '../utils/endpoint';
import { Layout, Menu, Card, Form, Input} from "antd";
import logo from '../assets/images/Kementerian_Ketenagakerjaan.png';
import { UserContext } from '../hooks/userContext';
import { useHistory } from 'react-router-dom';
import { CardActions } from '@mui/material';
import {CardHeader} from '@mui/material';

const theme = createTheme();

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessage] = useState("");
  const [regional, setRegional] = useState("");
  const [, setToken] = useContext(UserContext);

  const redirect = useHistory()

  const submitLogin = async () => {
      const requestOptions = {
          method: 'POST',
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: JSON.stringify(
              `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`
          ),
      };

      const response = await fetch(`${endpoint}/user/api/token`, requestOptions);
      const data = await response.json();

      if(!response.ok) {
          message.error("Isi data dengan benar");
          setErrorMessage(data.detail);
      } else {
          setToken(data.access_token);
          localStorage.setItem("email", username);
          localStorage.setItem("regional", data.regional);
          localStorage.setItem("level", data.level);
          redirect.push('/dashboard');
          window.location.reload();
      }
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      submitLogin();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'auto'
          }}
        >
          <Card
              className="card-signup header-solid h-full ant-card pt-4 width-100"
              bordered="false"
              style={{margin: '150px'}}
          >
            <CardHeader />
            <Stack direction="row" style={{ justifyContent: "center", display: "flex" }} spacing={1}>
              <Avatar alt="Remy Sharp" src={logo} 
              sx={{ width: '210px', height: '85px'}} />
            </Stack>
            <CardActions>
            <Box component="form" noValidate >
            <form className='form'>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email"
              name="username"
              autoComplete="off"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete={setPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{ backgroundColor: '#15406A' }}
              onClick={handleSubmit}
            >
              MASUK
            </Button>
            </form>
          </Box>
          </CardActions>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;